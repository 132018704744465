import { fetchApi } from '@/utils/axios'
import URL from '@/api/urlConfig'
import http from "@/utils/axios"
let demand = '/api/dscloud-ies-demand'; // 需求池
let URL2 ={
   //    分派/重新分派负责人
   ASSIGNWORKORDER: demand + '/demand_pool/web/assignWorkOrder',
   //  工单审核
   AUDITWORKORDER: demand + '/demand_pool/web/auditWorkOrder',
   // 结束工单 (统一客服工单)
   serviceFinishWorkOrder: demand + '/demand_pool/web/serviceFinishWorkOrder',
   //  结束工单 (内部-需求发起人)
   ONESELFFINISHWORKORDER: demand + '/demand_pool/web/oneselfFinishWorkOrder',
   //  结束工单 (用户)
   USERFINISHWORKORDER: demand + '/demand_pool/web/userFinishWorkOrder',
   // 内部发起工单
   INNERWORKORDER: demand + '/demand_pool/web/innerWorkOrder',
   // 400/统一客服发起工单
   SERVICEWORKORDER: demand + '/demand_pool/web/serviceWorkOrder',
   // 人员获取服务项目
   GETTENANTPROJECT:demand + '/user/tenant_project_list',
}

/**
 * 查询部门列表(需求池)
 * @returns 部门列表
 */
export const getDepartmentListForDemandPool = () => fetchApi(URL.GET_DEPARTMENT_LIST_FOR_DEMAND_POOL)
/**
 * 添加部门(需求池)
 * @param {Object} data 参数对象
 * @param {String} data.organizationName 新增组织名称
 * @param {String} data.parentOrganizationId 上级组织id
 * @returns 添加结果
 */
export const addDepartmentForDemandPool = (data) => fetchApi(URL.ADD_DEPARTMENT_FOR_DEMAND_POOL, data, 'post', 'data')
/**
 * 删除部门(需求池)
 * @param {String} organizationId 部门id
 * @returns 删除结果
 */
export const deleteDepartmentForDemandPool = (organizationId) =>
  fetchApi(`${URL.DELETE_DEPARTMENT_FOR_DEMAND_POOL}/${organizationId}`, null, 'delete')
/**
 * 编辑部门(需求池)
 * @param {Object} data 参数对象
 * @param {String} data.organizationName 编辑当前部门名称
 * @param {String} data.organizationId 编辑当前部门id
 * @returns 编辑结果
 */
export const editEepartmentForDemandPool = (data) => fetchApi(URL.EDIT_DEPARTMENT_FOR_DEMAND_POOL, data, 'put', 'data')
/**
 * 查询人员列表(需求池)
 * @param {Object} data 参数对象
 * @param {String} data.account 账号
 * @param {String} data.userName 用户昵称
 * @param {String} data.phoneNumber 用户手机号
 * @param {String} data.roleTypeId 角色类型id
 * @param {String} data.organizationId 组织架构id
 * @param {String} data.page 页数
 * @param {String} data.size 条数
 * @returns 人员列表
 */
export const getStaffListForDemandPool = (data) => fetchApi(URL.GET_STAFF_LIST_FOR_DEMAND_POOL, data, 'post', 'data')
/**
 * 查询角色类型列表(需求池)
 * @returns 角色类型列表
 */
export const getRoleTypeListForDemandPool = () => fetchApi(URL.GET_ROLE_TYPE_LIST_FOR_DEMAND_POOL)
/**
 * 添加人员(需求池)
 * @param {Object} data 参数对象
 * @param {String} data.userId 用户id
 * @param {String} data.roleIds 角色ids（多个角色用英文逗号分隔）
 * @param {String} data.organizationId 组织结构id
 * @returns 添加结果
 */
export const addStaffForDemandPool = (data) => fetchApi(URL.ADD_STAFF_FOR_DEMAND_POOL, data, 'post', 'data')
/**
 * 添加人员查询人员信息(需求池)
 * @param {Object} data 参数对象
 * @param {String} data.account 账号
 * @param {String} data.phoneNumber 用户手机号
 * @param {String} data.userId 用户id
 * @returns 人员列表
 */
export const addStaffSearchStaffInfoForDemandPool = (data) =>
  fetchApi(URL.ADD_STAFF_SEARCH_STAFF_INFO_FOR_DEMAND_POOL, data, 'post', 'data')
/**
 * 查询人员详情(需求池)
 * @param {String} userId 人员id
 * @returns 人员详情
 */
export const getStaffDetailForDemandPool = (userId) => fetchApi(`${URL.GET_STAFF_DETAIL_FOR_DEMAND_POOL}/${userId}`)
/**
 * 编辑人员(需求池)
 * @param {Object} data 参数对象
 * @param {String} data.userId 用户id
 * @param {String} data.roleIds 角色ids（多个角色用英文逗号分隔）
 * @param {String} data.organizationId 组织结构id
 * @returns 编辑结果
 */
export const editStaffForDemandPool = (data) => fetchApi(URL.EDIT_STAFF_FOR_DEMAND_POOL, data, 'put', 'data')
/**
 * 删除人员(需求池)
 * @param {String} userId 用户id
 * @returns 删除结果
 */
export const deleteStaffForDemandPool = (userId) =>
  fetchApi(`${URL.DELETE_STAFF_FOR_DEMAND_POOL}/${userId}`, null, 'delete')
/**
 * 获取需求分类列表(需求池)
 * @param {Object} params 参数对象
 * @param {Number} params.pageNum 页码
 * @param {Number} params.pageSize 页面大小
 * @returns 需求分类列表
 */
export const getDemandCategoryListFoDemandPool = (params) =>
  fetchApi(`${URL.GET_DEMAND_CATEGORY_LIST_FOR_DEMAND_POOL}/${params.pageNum}/${params.pageSize}`)
/**
 * 添加需求分类(需求池)
 * @param {Object} data 参数对象
 * @param {String} data.classifyName 需求分类名称
 * @param {String} data.parentClassifyId 父级需求分类id（添加一级分类时传null）
 * @returns 添加结果
 */
export const addDemandCategoryForDemandPool = (data) =>
  fetchApi(URL.ADD_DEMAND_CATEGORY_FOR_DEMAND_POOL, data, 'post', 'data')
/**
 * 编辑需求分类(需求池)
 * @param {Object} data 参数对象
 * @param {String} data.classifyId 主键id
 * @param {String} data.classifyName 需求分类名称
 * @returns 编辑结果
 */
export const editDemandCategoryForDemandPool = (data) =>
  fetchApi(URL.EDIT_DEMAND_CATEGORY_FOR_DEMAND_POOL, data, 'put', 'data')
/**
 * 删除需求分类(需求池)
 * @param {String} demandClassifyId 分类id
 * @returns 删除结果
 */
export const deleteDemandCategoryForDemandPool = (demandClassifyId) =>
  fetchApi(`${URL.DELETE_DEMAND_CATEGORY_FOR_DEMAND_POOL}/${demandClassifyId}`, null, 'delete')
//需求池-工单列表
export const requirementPoollist = (data) => fetchApi(URL.REQUIREMENT_POOL_LIST, data, 'post', 'data',true)
// 需求池-Web端需求池工单详情
export const requirementPooldetail = (data) => fetchApi(URL.REQUIREMENT_POOL_DETAIL + data, null, 'get')
// 需求池-需求类型
export const requirementDemandtypeall = (data) => fetchApi(URL.REQUIREMENT_DEMANDTYPE, data, 'post', 'data')
//需求池-工单状态
export const requirementDemandstate = (data) => fetchApi(URL.REQUIREMENT_DEMANDSTATE, null, 'get')
//需求池-项目
export const requirementProject = (data) => fetchApi(URL.REQUIREMENT_PROJECT, {}, 'get','params',true)
//需求池-企业信息
export const requirementEnterprise = (data) => fetchApi(URL.REQUIREMENT_ENTERPRISE, data, 'get')
//需求池-需求发起者、服务负责人、服务协作人
export const requirementHandleMsg = (data) => fetchApi(URL.REQUIREMENT_HANDLEMSG, data, 'get')
//需求池-需求分类
export const requirementClassify = (data) => fetchApi(URL.REQUIREMENT_CLASSIFY, data, 'get')
//需求池-需求服务类型
export const requirementDemandServertype = () => fetchApi(URL.REQUIREMENT_DEMANDSERVERTYPE, null, 'get')
//需求池-批量导出
export const requirementExport = (data) => {
  return http({
    url: URL.REQUIREMENT_EXPORT,
    responseType: 'blob',
    timeout: 120000,
    method: 'post',
    data: data
  })
}
// 获取需求分类
export const getclassFiy = () => {
  return fetchApi(URL.REQUIREMENT_GETCLASSFIY, null, 'get')
}
// 获取需求类别
export const getclassFiyType = () => {
  return fetchApi(URL.DEMANDSERVERTYPE, null, 'get')
}
//  内部需求类型下拉框
export function innerdemandType(params) {
  return fetchApi(URL.INNERDEMANDTYPE, '', 'get')
}
//  项目负责人拒收工单
export function rejectionOrder(data) {
  return fetchApi(URL.REJECTIONORDER, data, 'post', 'data')
}
// 协作人提交服务
export function cooperationSubmit(data) {
  return fetchApi(URL.COOPERATIONSUBMIT, data, 'post', 'data')
}
// 项目负责人提交服务
export function principalSubmit(data) {
  return fetchApi(URL.PRINCIPALSUBMIT, data, 'post', 'data')
}
// 项目负责人接收工单
export function receiveOrder(data) {
  return fetchApi(URL.RECEIVEORDER + data, '', 'put')
}
// 工单归档
export function pigeonhole(data) {
  return fetchApi(URL.PIGEONHOLE, data, 'post', 'data')
}
// 用户/统一服务审核
export function auditWorkorder(data) {
  return fetchApi(URL2.AUDITWORKORDER, data, 'post', 'data')
}
// 地点管理-列表
export const placeList = (data) => fetchApi(URL.WEBPLACELIST, data, 'get')
// 地点管理-添加
export const placeAdd = (data) => fetchApi(URL.WEBPLACEADD, data, 'post', 'data')
//  地点管理-编辑
export const placeUpdate = (data) => fetchApi(URL.WEBPLACEUPDATE, data, 'put', 'data')
//  地点管理-删除
export const placeDeleted = (id) => fetchApi(URL.WEBPLACEDELETED + id, null, 'delete')
// 地点管理-列表
export const tenantList = (data) => fetchApi(URL.WEBTENANTLIST, data, 'get')
// 地点管理-添加
export const tenantAdd = (data) => fetchApi(URL.WEBTENANTADD, data, 'post', 'data')
//  地点管理-编辑
export const tenantUpdate = (data) => fetchApi(URL.WEBTENANTUPDATE, data, 'put', 'data')
//  地点管理-删除
export const tenantDeleted = (id) => fetchApi(URL.WEBTENANTDELETED + id, null, 'delete')
//  地点管理-删除
export const getFeishuOpenid = (data) => fetchApi(URL.GETFEISHUOPENID, data, 'get')
// 完成回访
export function returnVisittype(data) {
  return fetchApi(URL.RETURNVISITTYPE, data, 'post', 'data')
}
//取消回访
export function cancelReturnVisit(params) {
  return fetchApi(URL.CANCELRETURNVISIT + params, '', 'put')
}
//  查询项目
export function findProject(params) {
  return fetchApi(URL.FINDPROJECT, '', 'get')
}
//模糊查询项目下企业信息
export function fuzzyretrieval(params) {
  return fetchApi(URL.FUZZYRETRIEVAL, params, 'get')
}
//模糊查询企业下的员工信息
export function customerFuzzyretrieval(params) {
  return fetchApi(URL.CUSTOMERFUZZYRETRIEVAL, params, 'get')
}
//   400创建工单
export function serviceWorkOrder(data) {
  return fetchApi(URL2.SERVICEWORKORDER, data, 'post', 'data')
}
// 模糊搜索分派/协作人列表
export function appGetDemanduserListbyUsername(data) {
  return fetchApi(URL.APPGETDEMANDUSERLISTBYUSERNAME, data, 'post', 'data')
}

//  获取地点下拉框数据 by 项目id
export function placeDownload(data) {
  return fetchApi(URL.placeDownload, data, 'get')
}
//   获取商户下拉框数据 by 项目id
export function tenantDownload(data) {
  console.log(data, '===');
  return fetchApi(URL.tenantDownload, data, 'get')
}
//需求池-项目
export const requirementProject2 = (data) => fetchApi(URL.getProjectDownBox, null, 'get')
//需求池-项目
export const requirementProject3 = (data) => fetchApi(URL.getProjectDownBox2, null, 'get')

//  联系人职务下拉框
export function linkmanDuty(params) {
  return fetchApi(URL.LINKMANDUTY, '', 'get')
}
//内部发起工单
export function innerWorkorder(data) {
  return fetchApi(URL2.INNERWORKORDER, data, 'post', 'data')
}
//需求池-工单列表
export const innerWorkOrderList = (data) => fetchApi(URL.innerWorkOrderList, data, 'post', 'data')

// 结束工单 (统一客服工单)
export function serviceFinishWorkOrder(data) {
  return fetchApi(URL2.serviceFinishWorkOrder, data, 'post', 'data')
}
// 结束工单 (用户)
export function userFinishWorkorder(data) {
  return fetchApi(URL2.USERFINISHWORKORDER, data, 'post', 'data')
}
// 结束工单 (内部-自行解决)
export function oneselfFinishWorkorder(data) {
  return fetchApi(URL2.ONESELFFINISHWORKORDER, data, 'post', 'data')
}
// 分派/重新分派负责人
export function assignWorkorder(data) {
    return fetchApi(URL2.ASSIGNWORKORDER, data, 'put', 'data')
}
// 项目负责人添加协作人
export function addCooperation(data) {
    return fetchApi(URL.ADDCOOPERATION, data, 'post', 'data')
}
// 项目负责人添加协作人
export function getDemandUserTree(data) {
    return fetchApi(URL.GETDEMANDUSERTREE, data, 'post', 'data')
}//  存储草稿
export function saveWorkOrderDraft(data) {
  return fetchApi(URL.saveWorkOrderDraft,  data, 'post', 'data')
}
//  删除草稿
export function removeWorkOrderDraft(data) {
  return fetchApi(URL.removeWorkOrderDraft,  data, 'get')
}
//   读取草稿
export function getWorkOrderDraft(data) {
  return fetchApi(URL.getWorkOrderDraft,  data, 'get')
}
//   判断是否有草稿
export function haveWorkOrderDraft(data) {
  return fetchApi(URL.haveWorkOrderDraft,  data, 'get')
}
//   判断是否有草稿
export function workOrderServerDescribe(data) {
  return fetchApi(URL.workOrderServerDescribe,  data, 'get')
}
//需求池-400获取项目
export const get400ProjectList = (data) => fetchApi(URL.get400ProjectList, null, 'get')
//需求池-孵化器/内部获取项目
export const getIncubatorProjectList = (data) => fetchApi(URL.getIncubatorProjectList, data, 'get')
//孵化器发起工单
export function incubatorWorkOrder(data) {
  return fetchApi(URL.INCUBATORWORKORDER, data, 'post', 'data')
}
// 删除服务配置
export function deleteIntroduce(id) {
  return fetchApi(URL.DELETEINTRODUCE+id, {}, 'post', 'data')
}
// 服务配置列表
export function IntroduceList(data) {
  return fetchApi(URL.INTRODUCELIST, data, 'post', 'data',true)
}
// 顺义获取项目接口
export function getShunyiAllProject(data) {
  return fetchApi(URL.GET_SHUNYI_ALL_PROJECTLIST, data, 'post', 'data',true);
}
// 添加服务配置
export function addIntroduce(data) {
  return fetchApi(URL.ADDINTRODUCE, data, 'post', 'data');
}
// 编辑服务配置
export function updateIntroduce(data) {
  return fetchApi(URL.UPDATEINTRODUCE, data, 'put', 'data');
}
export function IntroduceInfo(id) {
  return fetchApi(URL.INTRODUCEINFO+id, {}, 'get', 'data');
}
export function getTenantProject(id) {
  return fetchApi(URL2.GETTENANTPROJECT, {}, 'get', 'data');
}